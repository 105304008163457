import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../images/logo-omega-removebg-preview.png";

const menuItems = [
  { path: "/", label: "Accueil" },
  { path: "/services", label: "Services" },
  { path: "/compagnies", label: "Compagnies" },
  { path: "/destinations", label: "Destinations" },
  { path: "/contact", label: "Contacts" },
];

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const showMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div
      id="navigation"
      className="fixed-top navbar-light bg-faded site-navigation menu_dropdown"
    >
      <div className="container">
        <div className="row navigation-content">
          <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="site-logo">
              <Link to="/" className="navbar-logo">
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8">
            <div className="header_right" id="header-right-container">
              <nav
                id="main-menu"
                className={`ml-auto ${menuOpen ? "menu-open" : ""}`}
              >
                <ul>
                  {menuItems.map((item) => (
                    <li key={item.path}>
                      <Link
                        to={item.path}
                        className={`item menu-item ${
                          location.pathname === item.path
                            ? "current-sous-menu"
                            : ""
                        }`}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <div id="mobile_menu" onClick={showMenu}>
                <span className="mobile-menu-icon">
                  {menuOpen ? <FaTimes /> : <FaBars />}
                </span>
                {menuOpen && (
                  <nav
                    id="main-menu-mobile"
                    className="ml-auto mobile-main-menu"
                  >
                    <ul className="mobile-menu-content">
                      {menuItems.map((item) => (
                        <li key={item.path}>
                          <Link
                            to={item.path}
                            className={`item menu-item ${
                              location.pathname === item.path
                                ? "current-sous-menu"
                                : ""
                            }`}
                          >
                            {item.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
