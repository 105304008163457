import React from "react";
import { allcompanies } from "..//helper/companies";
import Companie from "../components/Companie";
import PageHeader from "./PageHeader";

export default function CompanyPage() {
  return (
    <div>
      <PageHeader page_title="NOS COMPAGNIES" />

      <section id="blog" className="blog_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12" data-aos="fade-up">
              <div className="section-title text-center wow zoomIn">
                <h3 className="text-uppercase">
                  Omega Voyage vous propose des compagnies spéciales
                </h3>
                <span></span>
                <p className="company-intro">
                  Partir en vacances pour trouver de l'inspiration et profiter
                  des meilleures offres en matière de vols mais aussi
                  d'hébergement et parfois même, d'activités, OMEGA VOYAGE met
                  à votre disposition plusieurs compagnies aériennes. Quels que
                  soient vos besoins, vous pourrez facilement comparer des
                  centaines d'offres et réserver votre voyage de manière
                  pratique et économique.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {allcompanies.map((item) => (
              <Companie company={item} key={item.id} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
