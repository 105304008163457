import React from 'react'
import home_page_image from "../images/background/background5.jpg";


export default function PageHeader({page_title}) {
  return (
    <section  id="home" className="home_parallax h-50"
        style={{
            backgroundImage:`url(${home_page_image})`,  backgroundSize:"cover", backgroundPosition:"center center" ,backgroundRepeat: "no-repeat",backgroundAttachment: "fixed",
        }}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="hero-text">
                        <h2 className="text-white text-center text-uppercase fw-bold mb-5">{page_title}</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
