import React from "react";

export default function Destination({item}) {
  return (
    <div className="col-sm-4 p-3 col-xs-12 ">
      <div className="grid">
        <figure className="effect-sadie">
          <img src={item.image} alt="" />
          <figcaption>
            <h4>{item.name}</h4>
          </figcaption>
        </figure>
      </div>
    </div>
  );
}
