import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/ContactPage";
import ServicePage from "./components/ServicePage";
import DestinationPage from "./components/DestinationPage";
import CompanyPage from "./components/CompanyPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import DetailService from "./components/DetailService";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/services" element={<ServicePage />} />
        <Route path="/services/:serviceId" element={<DetailService />} />
        <Route exact path="/compagnies" element={<CompanyPage />} />
        <Route exact path="/destinations" element={<DestinationPage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
