import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import PageHeader from "./PageHeader";
import image_about from "../images/call-action-personj.jpg";
import "../styles/contact.css";
import {
  FaEnvelopeOpen,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegRegistered,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";

export default function ContactPage() {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_8v48h8x",
        "template_9a2swhl",
        form.current,
        "fcwqG5uyYPN7u-2mF"
      )
      .then(
        (result) => {
          if (result.status === 200) {
            setIsLoading(false);
            toast.success("Votre message a été envoyé avec succès !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            if (form.current) {
              form.current.reset();
            }
          } else {
            setIsLoading(false);
            toast.error("Oooops l'envoie de votre message a échoué !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        },
        (error) => {
          setIsLoading(false);
          toast.error("Oooops l'envoie de votre message a échoué !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div>
        <PageHeader page_title="Contactez-nous" />

        <div className="container-xxl pt-5">
          <div className="container">
            <div
              className="text-center m-auto col-md-8 wow fadeInUp"
              data-wow-delay="0.1s"
              data-aos="fade-up"
            >
              <h2 className="mb-5 contact-welcome-text">
                Nous sommes disponibles pour toutes vos préoccupations
              </h2>
            </div>
            <div className="row g-4">
              <div
                className="col-lg-6 col-md-6"
                data-wow-delay="0.1s"
                data-aos="fade-up"
              >
                <h4 classsName="sous-titre">Nos Contacts & Références</h4>
                <p className="mb-4">
                  Pour tous vos besoins, veuillez nous contacter sur les
                  références ci-après ou rendez-vous dans notre agence
                </p>
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="d-flex align-items-center justify-content-center flex-shrink-0 background-second-color"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <span className="text-white">
                      <FaRegRegistered />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h5 className="text-primary">Enrégistrement</h5>
                    <p className="mb-0">
                      N° RCCM RB/COT/19 B 24529-02 BP 928 COTONOU
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="d-flex align-items-center justify-content-center flex-shrink-0 background-second-color"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <span className="text-white">
                      <FaMapMarkerAlt />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h5 className="text-primary">Adresse</h5>
                    <p className="mb-0">
                      Lot 1356 Vedoko carrefour la vie en face STATION PETROLEUM
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="d-flex align-items-center justify-content-center flex-shrink-0 background-second-color"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <span className="text-white">
                      <FaPhoneAlt />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h5 className="text-primary">Numéro téléphone</h5>
                    <p className="mb-0">(+229) 95 63 44 32 / 99 00 07 01</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex align-items-center justify-content-center flex-shrink-0 background-second-color"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <span className="text-white">
                      <FaEnvelopeOpen />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h5 className="text-primary">Adresse E-mail</h5>
                    <p className="mb-0">contact@omega-voyages-business.com</p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 wow fadeInUp form-contact "
                data-wow-delay="0.5s "
                data-aos="fade-up"
              >
                <h4 className="sous-titre titre-form text-warning">
                  Envoyez nous directement un message
                </h4>
                <p className="">
                  Pour toute préoccupation particulière veuillez remplir le
                  formulaire ci-dessous pour nous envoyer directement un mail.
                </p>
                <form className="my-3" ref={form} onSubmit={sendEmail}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="user_name"
                          name="user_name"
                          placeholder="votre nom et prénoms"
                          required
                        />
                        <label for="user_name">Nom et Prénoms</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="user_email"
                          name="user_email"
                          placeholder="votre adresse email"
                          required
                        />
                        <label for="user_email">Adresse e-mail</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="user_subject"
                          name="user_subject"
                          placeholder="Subject"
                          required
                        />
                        <label for="user_subject">Objet de votre demande</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control"
                          id="user_phone"
                          name="user_phone"
                          placeholder="votre numéro de téléphone"
                          required
                        />
                        <label for="user_phone">Numéro de téléphone</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Saisissez votre message ici ..."
                          id="message"
                          name="message"
                          style={{ height: "150px" }}
                          required
                        ></textarea>
                        <label for="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn fw-bold text-white background-second-color w-25 py-2 justify-content-center align-items-center d-flex flex-column"
                        type="submit"
                      >
                        {!isLoading ? (
                          "Envoyer"
                        ) : (
                          <Spinner animation="border" variant="light" />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container-xxl pt-3 pb-5" id="form-contact-container">
          <div className="container">
            <div className="row g-4">
              {/* <div
                className="col-lg-6  col-md-6 wow fadeInUp form-image-container"
                data-wow-delay="0.1s"
                data-aos="fade-up"
              >
                <img
                  src={image_about}
                  alt="contactez-nous"
                  style={{
                    objectFit: "contain",
                    width: "635px",
                    height: "475px",
                  }}
                />
              </div> */}
              <div
                className="col-lg-110 col-md-10 wow fadeInUp mt-3"
                data-wow-delay="0.3s"
                data-aos="fade-up"
              >
                {/* <iframe
                  title="Omega Voyages location on map "
                  className="gmap_iframe"
                  frameborder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src="https://maps.google.com/maps?width=635&amp;height=425&amp;hl=en&amp;q=cotonou,omega voyage&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe> */}
                <iframe
                  width="1275"
                  height="500"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?width=1920&amp;height=403&amp;hl=en&amp;q=99GW+FVG,%20Rte%20de%20Lom%C3%A9,%20Cotonou%20Cotonou+(OMEGA%20Voyages%20&amp;%20Business)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
