import React from 'react'
import Destination from "../components/Destination";
import {destinations} from "../helper/destinations";
import PageHeader from "../components/PageHeader";

export default function DestinationPage() {
  return (
    <div>
    
<PageHeader page_title="NOS DESTINATIONS"  />


<section id="top_deals" className="top_deals section-padding">
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12" data-aos="fade-up">
					<div className="section-title text-center">
						<h3 className="text-uppercase">Quelques de nos belles destinations</h3>
						<span></span>
						<p>Vous n’avez pas d’idées pour vos vacances et vous êtes perdus pour préparer votre voyage ? Faites confiance à <strong className="fw-bold">OMEGA Voyages & Business</strong> qui vous propose des destinations de rêve Vol + Hôtel pour des vacances adaptées à vos envies. Nous créons constamment de nouveaux itinéraires et de nouvelles destinations, pour que vous puissiez explorer le monde. Profitez de passionnantes     visites quotidiennes vers différentes destinations à travers le monde.</p>
					</div>
				</div>
			</div>
			<div className="row" data-aos="fade-up">
			{
                destinations.map((item)=><Destination item={item}  key={item.id} />)
            }
			</div>
			
		</div>
		
	</section>


    </div>
  )
}
