import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import background_image from "../images/background/home_background.jpg";
import { allServices } from "../helper/list_service";
import Service from "./Service";
import "../styles/single-animations.css";
import about_background from "../images/service-bg.jpg";
import image_about from "../images/faqs.jpg";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import newsletter_background from "../images/background/background10.jpg";
// import TestimonialPage from "./TestimonialPage";
import $ from "jquery";
import { FaArrowDown, FaGlobe, FaTrophy } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";

export default function Home() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    return () => {
      /*PRELOADER JS*/
      $(window).on("load", function () {
        // $('.loader-box').fadeOut();
        $(".preloader").delay(250).fadeOut("slow");
      });
      /*END PRELOADER JS*/
    };
  }, []);

  return (
    <div>
      {/* <div className="preloader">

</div> */}

      <section
        id="home"
        className="home_parallax"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="hero-text">
                <div className="welcome-info d-flex justify-content-center align-items-center">
                  <div className="loader">
                    <div className="face">
                      <div className="circle"></div>
                    </div>
                    <div className="face">
                      <div className="circle"></div>
                    </div>
                  </div>
                  <h1>Bienvenue</h1>
                  <div className="loader">
                    <div className="face">
                      <div className="circle"></div>
                    </div>
                    <div className="face">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>

                <h1 className="fs-1"> Nous organisons des voyages géniaux</h1>
                <p>
                  Des voyages qui restent à jamais gravés dans votre mémoire ?
                  Nous savons où aller.
                  <br />
                  Découvrez vos endroits préférés avec nous !
                </p>
                <Link
                  to="/destinations"
                  className="btn btn-default btn-home-bg fw-bold"
                >
                  Explorer plus
                </Link>
                <div className="spinner-container">
                  <div className="loader-box">
                    <div className="loading-wrapper">
                      <a href="/#why-choose-us">
                        <div className="loader">
                          <div className="loader-inner">
                            {" "}
                            <span className="godown-arrow">
                              <FaArrowDown />
                            </span>{" "}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="service" className="our_services">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title">
                <h2>Quelques-uns de nos services</h2>
                <span></span>
                <p className="section-service-intro">
                  Le recours aux services d’un spécialiste en organisation de
                  voyage d’affaires constitue la solution la plus simple pour
                  optimiser le coûts de vos déplacements professionnels et
                  d'autres options(vacances...). Chez OMEGA VOYAGE & Business,
                  spécialiste du Business Travel depuis des années, vous
                  bénéficierez de prestations personnalisées et de tarifs
                  préférentiels.Découvrez nos services et réservez sans soucis.
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            {allServices.slice(0, 3).map((item) => (
              <Service service={item} key={item.id} />
            ))}
          </div>
          <div className="text-center mt-3 justify-content-center align-items-center flex-column">
            <Link
              to="/services"
              className="btn btn-service-bg fw-bold btn-default"
            >
              Consulter plus de services{" "}
            </Link>
          </div>
        </div>
      </section>

      <section
        id="why-choose-us"
        className="why_choose section-padding"
        style={{
          backgroundImage: `url(${about_background})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12" data-aos="fade-up">
              <div className="section-title text-center">
                <h2 className="section-title-white">
                  Pourquoi nous faire appel ?
                </h2>
                <span className="section-title-white"></span>
                <p className="section-title-white  ">
                  Chaque fois, nous fournissons de meilleurs services. Pour
                  chacun de vos besoins, nous avons la solution adaptée.
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-4  col-xs-12" data-aos="fade-up">
              <div className="single-choose text-center">
                <span>
                  <FaGlobe />
                </span>
                <h4 className="text-center">
                  Voyagez au meilleur prix et économisez de l'argent
                </h4>
                <p>
                  Notre agence de voyage vous propose le prix des billets
                  d'avions parmi plusieurs compagnies aériennes pour réserver un
                  vol au tarif le plus intéressant.
                </p>
              </div>
            </div>
            <div className="col-sm-4  col-xs-12" data-aos="fade-up">
              <div className="single-choose text-center">
                <span>
                  <FaHandsHelping />
                </span>
                <h4 className="text-center">
                  Sérénité assurée et assistance en ligne 24 / 7
                </h4>
                <p>
                  Pour la sécurité et l’assurance, nous fournissons une
                  assistance à nos clients à tout moment, afin que vous puissiez
                  profiter de votre voyage et vous détendre.
                </p>
              </div>
            </div>
            <div className="col-sm-4  col-xs-12" data-aos="fade-up">
              <div className="single-choose text-center">
                <span>
                  <FaTrophy />
                </span>
                <h4 className="text-center ">
                  Expertise, conseil et suivi d’un professionnel
                </h4>
                <p>
                  Confiez-nous l'organisation de votre voyage, nous avons les
                  compétences requises pour vous conseiller, vous orienter au
                  mieux,et l'organiser à la hauteur de vos espérances.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-main">
        <div className="container about-container">
          <div className="row  about-container-row">
            <div
              className="col-lg-6 col-sm-12 col-xs-12 no-padding"
              data-aos="fade-up"
            >
              <div className="about-image">
                <img src={image_about} alt="" />
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-12 col-xs-12 no-padding"
              data-aos="fade-up"
            >
              <div className="about-content section-about">
                <h2 className="fw-bold mb-3 text-center text-uppercase">
                  à propos de nous
                </h2>
                <span></span>
                <p className="about-text-two mt-2">
                  Au cours de notre travail, nous avons organisé d'innombrables
                  voyages pour nos clients. Nous avons commencé comme un petit
                  bureau de tourisme, et bientôt nous avons élargi notre liste
                  d'offres. Aujourd'hui, nous avons une expérience précieuse des
                  voyages et nous pouvons vous conseiller les stations, villes
                  et pays les plus étonnants à visiter !
                </p>
                <p className="about-text-two mt-2">
                  Notre expérience, notre expertise et l’exigence continue d’un
                  service de qualité nous confortent encore plus aujourd’hui
                  comme leader sur le marché des voyages. De grandes compagnies
                  nous font confiance, preuve de notre professionnalisme.
                  Spécialisée dans le domaine du Travel Management, du Tourisme
                  et de l’Evénementiel, OMEGA VOYAGE & Business met son
                  expertise à votre disposition 24H /24 et 7 jours / 7 avants
                  pendant et après le voyage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="testimonial-demo section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" data-aos="fade-up">
              <div className="section-title">
                <h2 className="fs-2">
                  Ce que nos clients satisfaits pensent de nous
                </h2>
                <span></span>
                <p className="section-testimonial-intro">
                  Découvrez de nouvelles cultures et reposez-vous en toute
                  tranquillité avec OMEGA VOYAGE & Businesss ! Choisissez le pays que vous
                  voulez visiter et donnez à nos agents une estimation de la
                  durée de votre séjour; ils trouveront et proposeront les
                  circuits et les hôtels les plus appropriés.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <TestimonialPage />
          </div>
        </div>
      </section> */}

      <section
        className="newsletter-section"
        id="newsletter"
        style={{
          backgroundImage: `url(${newsletter_background})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          // backgroundAttachment: "fixed",
        }}
      >
        <div className="newsletter-container ">
          <div className="news-text-container container">
            <h1 className="text-center pt-5 fw-bold newsletter-title">
              Abonnez-vous à nos newsletters
            </h1>
            <p className="text-white col-md-8  text-center m-auto email-text">
              En saisissant votre adresse e-mail,vous serez les premiers à
              recevoir nos meilleures offres, nos promotions exclusives et nos
              conseils de voyage. De plus, nous vous tiendrons informés des
              endroits où vous pouvez voyager.
            </p>
            <div className="newsletter-content">
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  as={Col}
                  md="10"
                  controlId="validationCustomUsername"
                  className="m-auto"
                >
                  <Form.Label></Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                      className="col-sm-12 col-md-8"
                      type="text"
                      placeholder="Entrez votre adresse email"
                      aria-describedby="inputGroupPrepend"
                      required
                      md="8"
                      size="lg"
                    />
                    <Form.Control.Feedback type="invalid">
                      Entrez votre adresse email valide
                    </Form.Control.Feedback>
                    <Button
                      type="submit"
                      size="lg"
                      md="2"
                      className="background-second-color col-md-2 col-sm-12 text-white fw-bold "
                    >
                      S'abonner
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="feature">
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <p className="section-title bg-white text-start text-primary pe-3">
                  Why Us!
                </p>
                <h1 className="mb-4">Few Reasons Why People Choosing Us!</h1>
                <p className="mb-4">
                  Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                  Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                  sed stet lorem sit clita duo justo magna dolore erat amet
                </p>
                <p>
                  <i className="fa fa-check text-primary me-3"></i>Justo magna
                  erat amet
                </p>
                <p>
                  <i className="fa fa-check text-primary me-3"></i>Aliqu diam
                  amet diam et eos
                </p>
                <p>
                  <i className="fa fa-check text-primary me-3"></i>Clita erat
                  ipsum et lorem et sit
                </p>
                <a
                  className="btn btn-secondary rounded-pill py-3 px-5 mt-3"
                  href=""
                >
                  Explore More
                </a>
              </div>
              <div className="col-lg-6">
                <div className="rounded overflow-hidden">
                  <div className="row g-0">
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                      <div className="text-center background-second-color py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="img/experience.png"
                          alt=""
                        />
                        <h1
                          className="display-6 text-white"
                          data-toggle="counter-up"
                        >
                          25
                        </h1>
                        <span className="fs-5 fw-semi-bold text-white">
                          Years Experience
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                      <div className="text-center background-primary-color py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="img/award.png"
                          alt=""
                        />
                        <h1 className="display-6" data-toggle="counter-up">
                          183
                        </h1>
                        <span className="fs-5 fw-semi-bold text-black">
                          Award Winning
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                      <div className="text-center background-primary-color py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="img/animal.png"
                          alt=""
                        />
                        <h1 className="display-6" data-toggle="counter-up">
                          2619
                        </h1>
                        <span className="fs-5 fw-semi-bold text-black">
                          Total Animals
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                      <div className="text-center background-second-color py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="img/client.png"
                          alt=""
                        />
                        <h1
                          className="display-6 text-white"
                          data-toggle="counter-up"
                        >
                          51940
                        </h1>
                        <span className="fs-5 fw-semi-bold text-white">
                          Happy Clients
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
