import image1 from "../images/destinations/background1.jpg";
import image2 from "../images/destinations/Pays.jpg";
import image3 from "../images/destinations/background3.jpg";
import image4 from "../images/destinations/background4.jpg";
import image5 from "../images/destinations/background6.jpg";
import image6 from "../images/destinations/background8.jpg";
import image7 from "../images/destinations/malaisie-destination-2.jpg";
import image8 from "../images/destinations/australia-destination-3.jpg";
import image9 from "../images/destinations/destination-second.jpg";
import image10 from "../images/destinations/destination-third.jpg";
import image11 from "../images/destinations/canada.jpg";
import image12 from "../images/destinations/japon.jpg";
import image13 from "../images/destinations/amazone.jpg";
import image14 from "../images/destinations/portugal.jpg";
import image15 from "../images/destinations/maldives.jpg";
import image16 from "../images/destinations/madagascar.jpeg";
import image17 from "../images/destinations/greece.jpg";
import image18 from "../images/destinations/egypte.jpg";
import image19 from "../images/destinations/dubai.jpg";
import image20 from "../images/destinations/danemark.jpg";
import image21 from "../images/destinations/codeIvoire.jpg";
import image22 from "../images/destinations/Inde.jpg";
import image23 from "../images/destinations/senegal.jpg";
import image24 from "../images/destinations/belgique.jpg";

export const destinations = [
  {
    id: 13,
    name: "Bénin",
    image: image13,
  },
  {
    id: 13,
    name: "Sénégal",
    image: image23,
  },

  {
    id: 15,
    name: "Côte d'Ivoire",
    image: image21,
  },
  {
    id: 14,
    name: "Egypte",
    image: image18,
  },
  {
    id: 6,
    name: "Afrique du sud",
    image: image6,
  },
  {
    id: 22,
    name: "Madagascar",
    image: image16,
  },
  {
    id: 1,
    name: "Etats-Unis",
    image: image1,
  },
  {
    id: 2,
    name: "Hongrie",
    image: image2,
  },
  {
    id: 3,
    name: "Chine",
    image: image3,
  },
  {
    id: 4,
    name: "Turquie",
    image: image4,
  },
  {
    id: 5,
    name: "Indonésie",
    image: image5,
  },

  {
    id: 7,
    name: "Malaisie",
    image: image7,
  },
  {
    id: 8,
    name: "Australie",
    image: image8,
  },
  {
    id: 9,
    name: "France",
    image: image9,
  },
  {
    id: 10,
    name: "Allemagne",
    image: image10,
  },
  {
    id: 11,
    name: "Canada",
    image: image11,
  },
  {
    id: 12,
    name: "Japon",
    image: image12,
  },

  {
    id: 16,
    name: "Greece",
    image: image17,
  },
  {
    id: 17,
    name: "Dubaï",
    image: image19,
  },
  {
    id: 18,
    name: "Danemark",
    image: image20,
  },
  {
    id: 19,
    name: "Inde",
    image: image22,
  },
  {
    id: 20,
    name: "Portugal",
    image: image14,
  },
  {
    id: 21,
    name: "îles Maldives",
    image: image15,
  },

  {
    id: 23,
    name: "Belgique",
    image: image24,
  },
];