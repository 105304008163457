import React from "react";
import { useParams } from "react-router-dom";
import { allServices } from "../helper/list_service";
import PageHeader from "./PageHeader";
export default function DetailService() {
  const { serviceId } = useParams();
  const currentService = allServices.find(
    (service) => service.id === serviceId
  );

  return (
    <>
      <PageHeader page_title="DETAILS SUR LE SERVICE" />
      <section className="mt-5 container">
        <h3 className="mb-3">Informations sur le service</h3>
        <div className="row my-5">
          <div className="col-md-4 border">
            <img
              src={currentService.image}
              alt={currentService.title}
              className="w-100 position-relative"
            />
          </div>
          <div className="col-md-8">
            <h2 className="text-primary">{currentService.title}</h2>
            <p>{currentService.description}</p>
          </div>
        </div>
      </section>
      {currentService.title === "Réservation d' hôtel" &&
        currentService.hotel_name ===
          "Golden Tulip Cotonou" && (
            <section className="container mb-5">
              <h2 className="text-center text-primary">
                Quelques Hôtels partenaires
              </h2>
              <h3 className="text-left text-warning">{ currentService.hotel_name}</h3>
              <p className="my-3">{currentService.golden_description}</p>

              <div className="row">
                {currentService.golden_images.map((image) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-sm-1 ">
                      <img
                        src={image}
                        alt="golden tulip"
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </section>
          )}
    </>
  );
}
