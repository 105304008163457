import moneyGram from "../images/transfert/MoneyGram-logo.jpg";
import western from "../images/transfert/Western-union-logo.jpg";
// eslint-disable-next-line no-unused-vars
import image1 from "../images/services/bureau2.png";
import image2 from "../images/services/cars-together.jpeg";
import image3 from "../images/services/credit-card.jpg";
import image4 from "../images/services/yerika-jus.jpg";
import image5 from "../images/services/hotels.jpeg";
import image6 from "../images/services/travel.jpeg";
import image7 from "../images/services/event1.jpg";
import image8 from "../images/services/tourism1.jpg";
import image1_golden from "../images/golden_tulip/75794415_L.jpg";
import image2_golden from "../images/golden_tulip/61336350_L.jpg";
import image3_golden from "../images/golden_tulip/64127043_L.jpg";
import image4_golden from "../images/golden_tulip/67670154_L.jpg";
import image5_golden from "../images/golden_tulip/72518866_L.jpg";
import image6_golden from "../images/golden_tulip/74583809_L.jpg";
import image7_golden from "../images/golden_tulip/75010608_L.jpg";
import image8_golden from "../images/golden_tulip/3556376_L.jpg";

export const allServices = [
  {
    id: "1",
    title: "Tourisme",
    image: image8,
    description: `
        Nous fournissons à nos clients un service tel que le guide touristique afin d'assurer aux gens toutes les informations nécessaires à tout moment.
        `,
  },
  {
    id: "2",
    title: "Location de voiture",
    image: image2,
    description: `
        Toutes catégories de véhicules disponibles : berline, van, 4X4, luxe, mini bus et bus avec votre partenaire OMEGA VOYAGE & Business. Nous travaillons pour assurer votre sécurité au volant.
        `,
  },
  {
    id: "3",
    title: "Evenementiels",
    image: image7,
    description: `
        Nous organisons tous vos événements professionnels.Parlez nous de vos évenements et projets et laissez nous les gérer et mieux les organiser pour vous. 
        `,
  },
  {
    id: "4",
    title: "Réservation d' hôtel",
    image: image5,
    description: `
      Réservez votre hôtel avec OMEGA VOYAGE & Business, c'est l'assurance de dénicher facilement et rapidement un établissement offrant des prestations de qualité. 
        `,
    hotel_name : "Golden Tulip Cotonou",
    golden_photo : "Golden Tulip Cotonou",
    golden_images: [
      image1_golden,
      image2_golden,
      image3_golden,
      image4_golden,
      image5_golden,
      image6_golden,
      image7_golden,
      image8_golden,
    ],

    golden_description: `
     Le Golden Tulip Cotonou est un hôtel situé dans la ville de Cotonou, au Bénin. C'est un établissement quatre étoiles qui propose des chambres confortables, des installations de conférence, un restaurant et d'autres commodités pour les voyageurs. Si vous avez besoin de plus d'informations sur cet hôtel, comme les tarifs, les disponibilités ou les services offerts, n'hésitez pas à poser des questions spécifiques. Nous serons ravi de vous aider davantage.
     Découvrez le luxe, le confort et l'hospitalité au Golden Tulip Le Diplomate Cotonou. Hôtel 4 étoiles offre un mélange harmonieux d'élégance moderne et de charme culturel, garantissant une expérience véritablement enrichissante à chaque client. Réservez votre séjour avec nous embarquez pour un voyage de luxe, de détente et d'exploration culturelle.
    `,
  },
  {
    id: "5",
    title: "Assurance voyage",
    image: image6,
    description: `Nos équipes sont à votre disposition pour vous aider dans toutes vos problématiques liées au voyage. Réservez dès maintenant votre vol pas cher pour la destination qui vous fait rêver.
        `,
  },
  {
    id: "6",
    title: "Fourniture de divers services",
    image: image1,
    description: `Nous mettons à votre disposition une vaste sélection de produits de qualité supérieure à des tarifs compétitifs. Quels que soient les services que vous offrez, nous sommes là pour vous offrir une variété de fournitures qui répondent à vos besoins.
        `,
  },
];

export const moneyTransferts = [
  {
    id: "7",
    title: "Western Union",
    image: western,
    description: `
        Western Union est un moyen de transfert qui génère également des revenus à partir du change de devises.
        `,
  },
  {
    id: "8",
    title: "MoneyGram ",
    image: moneyGram,
    description: `
        Avec MoneyGram vous avez de nombreuses options pour envoyer et recevoir de l'argent sur un compte bancaire,un portefeuille mobile...
        `,
  },
];
export const AutreServices = [
  {
    id: "9",
    title: "Vente de jus de fruits",
    image: image4,
    description: `
        Nous disposons des jus de fruits de qualité et au meilleur prix .
        `,
  },
  {
    id: "10",
    title: "Vente de carte de crédit",
    image: image3,
    description: `
        Nous disposons des cartes de crédit pour vos différentes courses .
        `,
  },
];
