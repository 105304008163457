import React from "react";
import PageHeader from "../components/PageHeader";
import { allServices } from "../helper/list_service";
import { moneyTransferts } from "../helper/list_service";
import { AutreServices } from "../helper/list_service";
import Service from "../components/Service";
import AutreService from "../components/AutreService";
import TransfertService from "../components/TransfertService";
import moneyGram from "../images/transfert/MoneyGram-logo.jpg";
import western from "../images/transfert/Western-union-logo.jpg";
import { Link } from "react-router-dom";
export default function ServicePage() {
  return (
    <div>
      <PageHeader page_title="NOS SERVICES" />

      <section id="service" className="our_services ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title mt-3">
                <h3 className="text-uppercase">
                  OMEGA Voyages & Business vous offre des services de qualité
                </h3>
                <span></span>
                <p className="section-service-intro">
                  Le recours aux services d’un spécialiste en organisation de
                  voyage d’affaires constitue la solution la plus simple pour
                  optimiser les coûts de déplacements professionnels de vos
                  collaborateurs. Chez OMEGA Voyages & Business, spécialiste du
                  Business Travel depuis des années, vous bénéficierez de
                  prestations personnalisées et de tarifs préférentiels.
                  Découvrez nos services et réservez sans soucis.
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            {allServices.map((item) => (
              <Service service={item} key={item.id} />
            ))}
          </div>
        </div>
      </section>

      <section className="money-transfert-section">
        <div className="container">
          <h2 className="text-center my-3">Transfert d'argent</h2>

          <div className="row text-center justify-content-center py-5">
            {moneyTransferts.map((item) => {
              return (
                <>
                  <div
                    className="col-lg-4 col-sm-4 col-xs-12 service-card"
                    data-aos="fade-up"
                  >
                    <div
                      className="card"
                      // style={{ width: "18rem" }}
                      data-aos="fade-up"
                      key={item.title}
                    >
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title fw-bold text-primary text-uppercase">
                          {item.title}
                        </h5>
                        <p className="card-text">
                          {item.description.length > 145
                            ? item.description.slice(0, 145) + "..."
                            : item.description}
                        </p>
                      </div>
                      <div className="">
                        {/* <a
                          href="#"
                          className="text-warning fw-bold d-inline-block mt-3 mb-2 float-end fst-italic px-5"
                        >
                          Voir détails...
                        </a> */}
                        {/* <Link
                          to={`/services/${item.id}`}
                          className="text-warning fw-bold d-inline-block mt-3 float-end fst-italic mb-2 px-5"
                        >
                          Voir détails...
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      <section className="autre-service-section our_services">
        <div className="container">
          <h2 className="text-center my-3">Autres services</h2>

          <div className="row text-center justify-content-center">
            {AutreServices.map((item) => (
              <AutreService service={item} key={item.id} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
