import image1 from "../images/compagnies/Compagnies.jpg";
import image2 from "../images/compagnies/Compagnies (1).jpg";
import image3 from "../images/compagnies/Compagnies (2).jpg";
import image4 from "../images/compagnies/Compagnies (7).jpg";
import image5 from "../images/compagnies/Compagnies (5).jpg";
import image6 from "../images/compagnies/Compagnies (10).jpg";
import image7 from "../images/compagnies/Compagnies (3).jpg";
import image8 from "../images/compagnies/Compagnies (11).jpg";
import image9 from "../images/compagnies/Compagnies (6).jpg";
import image10 from "../images/compagnies/Compagnies (8).jpg";
import image11 from "../images/compagnies/Compagnies (9).jpg";
import image12 from "../images/compagnies/Compagnies (13).jpg";
import image13 from "../images/compagnies/Compagnies (14).jpg";
import image14 from "../images/compagnies/Compagnies (12).jpg";
import image15 from "../images/compagnies/Compagnies (4).jpg";
import image16 from "../images/compagnies/air-canada.jpg";
import image17 from "../images/compagnies/mali.jpg";
import image18 from "../images/compagnies/lufthansa.jpg";
import image19 from "../images/compagnies/safair.jpg";
import image20 from "../images/compagnies/uganda.jpg";
import image21 from "../images/compagnies/kenya.jpg";
import image22 from "../images/compagnies/precision-air.png";
import image23 from "../images/compagnies/south-africa.png";
import image24 from "../images/compagnies/corsair-logo.jpg";

export const allcompanies = [
  {
    id: 1,
    name: "Air France",
    image: image1,
    description: `Voyager avec nous et Choisissez votre destination, visitez nos cabines et vivez l'expérience Air France lors de votre prochain voyage.`,
  },
  {
    id: 2,
    name: "Brussels airlines",
    image: image2,
    description: `Fondée en 2002, Brussels Airlines est la compagnie aérienne nationale belge et a son siège à l'aéroport de Bruxelles, Brussels Airport.`,
  },
  {
    id: 3,
    name: "Turkish airlines",
    image: image3,
    description: `Première compagnie aérienne de Turquie, Turkish Airlines (TK) est celle qui dessert le plus de pays, avec 287 destinations autour du monde`,
  },
  {
    id: 4,
    name: "Air Côte d'Ivoire",
    image: image4,
    description: `Air Côte d'Ivoire est la principale compagnie aérienne ivoirienne, le pavillon national. Fondée en 2012,ses activités principales sont le transport`,
  },
  {
    id: 5,
    name: "Air burkina",
    image: image5,
    description: `Air Burkina est une compagnie aérienne du Burkina Faso basée à Ouagadougou. C'est la compagnie historique de l'état burkinabè qui est actuellement majoritaire.`,
  },
  {
    id: 6,
    name: "Royal air maroc",
    image: image6,
    description: `La Royal Air Maroc est une compagnie aérienne marocaine basée à l'Aéroport Mohammed V de Casablanca. Elle dessert près de 90 destinations dans le monde`,
  },
  {
    id: 7,
    name: "ASky",
    image: image7,
    description: `ASky est une compagnie aérienne panafricaine, basée au Togo qui dessert 22 destinations d'Afrique de l'Ouest et du centre.`,
  },
  {
    id: 8,
    name: "RwandAir",
    image: image8,
    description: `RwandAir est une compagnie aérienne rwandaise, basée dans l'aéroport de Kigali à Kigali, la capitale du Rwanda. Elle exploite des vols à travers le Rwanda`,
  },
  {
    id: 9,
    name: "Ethiopian Air",
    image: image9,
    description: `Ethiopian Airlines (ET) est le transporteur aérien porte-drapeau de l'Éthiopie et membre de Star Alliance. La compagnie propose des vols sans escale vers 20 destinations`,
  },
  {
    id: 10,
    name: "Emirates",
    image: image10,
    description: ` Le groupe Emirates est basé à Dubai.La compagnie aérienne Emirates est une pure émanation des Émirats Arabes Unis, fédération qui a vu le jour en 1971, lors du retrait des Britanniques`,
  },
  {
    id: 11,
    name: "Quatar Airways",
    image: image11,
    description: `Transporteur national de l'État du Qatar, Qatar Airways est devenu un sponsor ... de partenariats sportifs internationaux qui incluent notamment des organes`,
  },
  {
    id: 12,
    name: "Senegal Airlines",
    image: image12,
    description: `Senegal Airlines était une compagnie aérienne sénégalaise qui est au coeur du Plan Sénégal Émergent avec pour ambition d'ériger un véritable hub aérien régional`,
  },
  {
    id: 13,
    name: "Tunisair",
    image: image13,
    description: `Tunisair (TU) est le transporteur national de la Tunisie. Basée à Tunis, elle opère depuis une plate-forme de l'aéroport international Tunis-Carthage (TUN)`,
  },
  {
    id: 14,
    name: "Mauritania Airlines",
    image: image14,
    description: `Mauritania Airlines International est la compagnie nationale de Mauritanie, lancée en décembre 2010 précipitant l'arrêt de Mauritania Airways`,
  },
  {
    id: 15,
    name: "Ceiba",
    image: image15,
    description: `CEIBA Intercontinental est une compagnie aérienne équatoguinéenne dont le siège et le principal centre d'activités se trouvent à Malabo`,
  },
  {
    id: 16,
    name: "Safair",
    image: image19,
    description: `Safair est une compagnie aérienne sud-africaine, basée à Johannesburg et crée en 1965. Elle est basée à l'Aéroport international de Johannesburg avec des bases secondaires au Cap, à Singapour, en Grande-Bretagne et en Irlande.`,
  },
  {
    id: 17,
    name: "Lufthansa",
    image: image18,
    description: `Lufthansa est une compagnie aérienne privée allemande ainsi que la première compagnie aérienne européenne en nombre de passagers transportés devant Ryanair et Air France-KLM. Elle est cofondatrice et pilier européen de Star Alliance.`,
  },
  {
    id: 18,
    name: "Air Canada",
    image: image16,
    description: `Air Canada est la plus grande compagnie aérienne nationale du Canada. Elle assure des vols intérieurs et internationaux sur les cinq continents depuis ses plates-formes de correspondance à Toronto-Pearson, Montréal-Trudeau, Vancouver et Calgary. Air Canada est la 15ᵉ plus grande compagnie aérienne au monde.`,
  },
  {
    id: 19,
    name: "Air Uganda",
    image: image20,
    description: `Uganda est une compagnie aérienne privée de l'Ouganda, fondée en 2007, qui appartient au groupe Celestair, un fonds de l'Aga Khan. Elle intègre l'IATA le 23 mai 2014`,
  },
  {
    id: 20,
    name: "Kenya Airways",
    image: image21,
    description: `Kenya Airways, est la compagnie aérienne porte-drapeau du Kenya. La société a été fondée en 1977, après la dissolution d'East African Airways. Son siège social est situé à Embakasi, Nairobi, avec son hub à l'aéroport international Jomo Kenyatta.`,
  },
  {
    id: 21,
    name: "Sky Mali",
    image: image17,
    description: `Sky Mali est une compagnie aérienne malienne. Le projet prend forme en 2019 et elle fut fondée officiellement en juillet 2020 grâce à des fonds privés et commença ses activités en 2020 en pleine pandémie de Covid-19`,
  },
  {
    id: 22,
    name: "South africa airways",
    image: image23,
    description: `South African Airways ou Suid-Afrikaanse Lugdiens est une grande compagnie aérienne d'Afrique du Sud après en avoir été la compagnie aérienne nationale. Elle dessert plus de quarante destinations à partir de l'aéroport international OR Tambo ainsi que l'aéroport international du Cap.`,
  },
  {
    id: 23,
    name: "Corsair",
    image: image24,
    description: `Corsair, anciennement Corsairfly, est une compagnie aérienne française spécialisée dans le transport long courrier. Fondée en 1981, elle est basée à l'aéroport de Paris-Orly et dessert principalement les départements d'outre-mer.`,
  },
  {
    id: 24,
    name: "Precicion Air",
    image: image22,
    description: `Precision Air (code AITA PW) est une compagnie aérienne privée de Tanzanie créée en 1993. Appareil assurant la liaison entre Mwanza et Kilimandjaro.`,
  },
];
