import React from "react";
import { Link } from "react-router-dom";

export default function Companie({ company }) {
  return (
    <div className="col-lg-3 col-sm-4 col-xs-12" data-aos="fade-up">
      <div className="single_home_blog">
        <div className="post-slide">
          <div className="post-img text-center">
            <Link href="#">
              <img src={company.image} alt="" />
            </Link>
          </div>
          <div className="post-content">
            <h5 className="post-title text-center">{company.name}</h5>
            <p className="post-description text-center">
              {company.description.substring(0, 110)}...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
