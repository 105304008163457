import React from "react";

export default function AutreService({ service }) {
  return (
    <>
      <div
        className="col-lg-4 col-sm-4 col-xs-12 service-card my-2"
        data-aos="fade-up"
      >
        <div
          className="card"
          // style={{ width: "18rem" }}
          data-aos="fade-up"
          key={service.title}
        >
          <img src={service.image} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title fw-bold text-primary text-uppercase">
              {service.title}
            </h5>
            <p className="card-text">
              {service.description.length > 145
                ? service.description.slice(0, 145) + "..."
                : service.description}
            </p>
          </div>
          {/* <div className="">
            <a
              href="#"
              className="text-warning fw-bold d-inline-block mt-3 float-end fst-italic px-5 mb-2"
            >
              Voir détails...
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
}
