import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";
import image1 from "../images/compagnies/Compagnies.jpg";
import image2 from "../images/compagnies/Compagnies (1).jpg";
import image3 from "../images/compagnies/Compagnies (2).jpg";
import image4 from "../images/compagnies/Compagnies (7).jpg";
import image5 from "../images/compagnies/Compagnies (5).jpg";
import image6 from "../images/compagnies/Compagnies (10).jpg";
import logo from "../images/logo-omega-removebg-preview.png";

import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaRegRegistered } from "react-icons/fa";

export default function Footer() {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-3 mt-0 wow fadeIn"
      data-wow-delay="0.1s"
      id="footer-section"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-3">Liens utiles</h4>
            <a className="btn btn-link" href="/#about">
              A propos{" "}
            </a>
            <Link className="btn btn-link" to="/contact">
              Contacts
            </Link>
            <Link className="btn btn-link" to="/services">
              Services
            </Link>
            {/* <Link className="btn btn-link" to="/destinations">
              Destinations
            </Link>
            <Link className="btn btn-link" to="/compagnies">
              Compagnies
            </Link> */}
            <div className="d-flex flex-column">
              <h5 className="text-warning mt-5">Réseaux sociaux</h5>
              <a
                href="https://www.facebook.com/omegavoyages/"
                className="d-flex justify-content-start align-items-center my-2"
              >
                <FaFacebook size={25} />
                <span className="mx-2 d-inline-block">
                  OMEGA Voyages & Business
                </span>
              </a>
              {/* <a href="">
                <span>
                  {" "}
                  <FaInstagram />
                </span>
              </a> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <h4 className="text-white mb-3"> Contacts</h4>
            <p className="mb-2">
              <span className="adress-icon text-second-color me-2">
                <FaRegRegistered />
              </span>
              N° RCCM RB/COT/19 B 24529 - 02 BP 928 COTONOU
            </p>
            <p className="mb-2">
              <span className="adress-icon text-second-color me-2">
                <FaMapMarkerAlt />
              </span>
              Lot 1356 Vedoko carrefour la vie en face STATION PETROLEUM
            </p>

            <p className="mb-2">
              <span className="adress-icon text-second-color me-2">
                <FaPhoneAlt />
              </span>
              (+229) 95 63 44 32 / (+229) 99 00 07 01
            </p>
            <p className="mb-2">
              <span className="adress-icon text-second-color me-2">
                <FaEnvelope />
              </span>
              contact@omega-voyages-business.com
            </p>
          </div>
          {/* <div className="col-lg-4 col-md-6">
            <h4 className="text-white mb-3"> Compagnies</h4>
            <div className="row g-2 pt-2">
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image1} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image2} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image3} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image4} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image5} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image6} alt="" />
              </div>
            </div>
          </div> */}
          <div className="col-lg-3 col-md-6">
            <div className="position-relative mx-auto">
              <img
                src={logo}
                alt="Omega Voyages"
                style={{ width: "250px", height: "auto" }}
                className="omega-logo"
              />
            </div>
            <div className="row g-2 pt-2">
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image1} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image2} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image3} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image4} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image5} alt="" />
              </div>
              <div className="col-4">
                <img className="img-fluid bg-light p-1" src={image6} alt="" />
              </div>
            </div>
            {/* <p className="text-justify">
              Notre expérience, notre expertise et l’exigence continue d’un
              service de qualité nous confortent encore plus aujourd’hui comme
              leader sur le marché des voyages. De grandes compagnies nous font
              confiance, preuve de notre professionnalisme.
            </p> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-9 text-center text-md-start mb-3 mb-md-0">
              <p>
                Un service après vente et nous sommes disponibles 24h/24h{" "}
                {/* <Link className="" to="">
                  <strong className="text-primary-color"> DSP Africa</strong>
                </Link> */}
              </p>
              <p className="copyright-content">
                Copyright &copy;2022
                <Link className=" mx-2 " to="#">
                  <strong className="text-second-color ">
                    {" "}
                    OMEGA Voyages & Business
                  </strong>
                </Link>
                <span>Tout droit réservé !</span>
              </p>
              <div className="d-flex justify-content-between col-md-5 mt-2">
                {/* <a
                  href=""
                  className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                  title="Politique de confidentialité "
                >
                  Politique de confidentialité
                </a>

                <a
                  href=""
                  className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe mx-2"
                  title="Politique relative aux Cookies "
                >
                  Politique relative aux Cookies
                </a> */}
              </div>
            </div>
            {/* <div className="col-md-3 text-center text-md-end">
              <div className="footer-menu">
                <div className="d-flex">
                  <a
                    className="btn btn-outline-light btn-social mr-1"
                    href="https://www.facebook.com/omegavoyages/"
                  >
                    <FaFacebookF />
                  </a>
                  <a className="btn btn-outline-light btn-social" href="">
                    <span>
                      {" "}
                      <FaInstagram />
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div>
        <a
          href="https://api.whatsapp.com/send?phone=22995634432&text=Je%20vous%20contacte%20depuis%20votre%20plateforme%20Omega%20Voyages%20&%20Business"
          target="_blank"
          title="Ecrivez nous directement sur whatsapp"
          className="btn btn-lg btn-success chat-whathsapp btn-lg-square rounded-circle "
          rel="noreferrer"
        >
          {/* <span className="adress-icon text-white me-2">
          </span> */}
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
}
