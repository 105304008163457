import React from 'react'
import PageHeader from './PageHeader'
import {Link} from 'react-router-dom'
import { FaExclamationTriangle } from 'react-icons/fa'

export default function NotFound() {
  return (
    <div>
        <PageHeader page_title="Votre page est introuvable !"/>
       
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                   <span style={{fontSize:"100px"}} className=" text-primary-color"><FaExclamationTriangle/></span>
                    <h1 className="display-1">404</h1>
                    <h1 className="mb-4">Page Introuvable</h1>
                    <p className="mb-4">Nous sommes désolé ! La page que vous recherchez ne se trouve pas sur notre site web. Veuillez retouner sur la page d'accueil ou réssayez avec une autre page correcte.</p>
                    <Link className="btn background-second-color text-white fw-bold rounded-pill py-3 px-5" to="/">Retourner sur l'Accueil</Link>
                    
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
